.AddMusic {
    &__item {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &__title {
            font-size: 26px;
            font-weight: 800;
            margin-bottom: 10px;
        }
        &__form {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            &.grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 20px;
                align-items: end;
            }
            .control {
                display: flex;
                flex-direction: column;
                gap: 5px;
                label {
                    font-weight: 500;
                    color: #2e3a59;
                }
                input {
                    border-color: #58617a;
                    height: 40px;
                    border-radius: 8px;
                }
                select {
                    border-color: #58617a;
                    height: 40px;
                    border-radius: 8px;
                }
                .multiselect {
                    display: flex;
                    gap: 10px;
                    &__input {
                        display: flex;
                        select {
                            flex: 1 1;
                            min-width: 150px;
                            border-radius: 8px 0 0 8px;
                        }
                        button {
                            border-radius: 0 8px 8px 0;
                            width: 40px
                        }
                    }
                    &__result {
                        display: flex;
                        gap: 5px;
                        span {
                            cursor: pointer;
                            background-color: #2e3a59;
                            height: 40px;
                            padding: 9px 15px;
                            color: white;
                            border-radius: 8px;
                        }
                    }
                }
            }
            button {
                background-color: #2e3a59;
                width: 150px;
                height: 40px;
                color: white;
                border-radius: 8px;
            }
        }
    }
}