body {
    font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}
  
a {
    cursor: pointer;
    text-decoration: none;
}

button {
    padding: 0;
    border: none;
    cursor: pointer;
}

//Page
@import 'pages/AddMusic';


.scroll_hidden {
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}
.scroll_playlist {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px; 
    }
    &::-webkit-scrollbar-track {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 9px; 
    }
}
[data-theme="light"] {
    .scroll_playlist {
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #5D528C;
        }
    }
}
[data-theme="dark"] {
    .scroll_playlist {
        &::-webkit-scrollbar-track {
            background: #0D122F;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #626784;
        }
    }
}
